import swal from "sweetalert";
import { addEntry, fetchEntrys, updateEntry } from "../reducers/EntrySlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchEntrys = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("entries", (entry) => entry.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchEntrys(data));
				FetchLiveEntrys(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("entries", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateEntry(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveEntrys = (
	token,
	instLinker,
	online,
	dispatch,
	entrys
) => {
	fetch(`${ApiUrl}/entry/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.entrys.map((onlineEntry) => {
					let localEntry = entrys.find(
						(item) => parseInt(item.linker) === parseInt(onlineEntry.linker)
					);
					if (!localEntry) {
						saveToIDB("entries", onlineEntry.linker, onlineEntry);
						dispatch(updateEntry(onlineEntry));
					} else if (parseInt(localEntry.trace) > parseInt(onlineEntry.trace)) {
						updateLiveEntry(localEntry, token, dispatch);
					} else if (parseInt(localEntry.trace) < parseInt(onlineEntry.trace)) {
						saveToIDB("entries", onlineEntry.linker, onlineEntry);
						dispatch(updateEntry(onlineEntry));
					}
				});

				entrys.map((entry) => {
					if (!entry.id || entry.id == null) {
						addLiveEntry(entry, instLinker, token, dispatch);
					} else if (parseInt(entry.live) === 0) {
						addLiveEntry(entry, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveEntry = (entry, instLinker, token, dispatch) => {
	dispatch(updateEntry({ ...entry, live: 0 }));
	saveToIDB("entries", entry.linker, { ...entry, live: 0 });
	fetch(`${ApiUrl}/entry/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...entry,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateEntry(res.entry));
				saveToIDB("entries", res.entry.linker, res.entry);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveEntry = (entry, token, dispatch) => {
	dispatch(updateEntry({ ...entry, live: 0 }));
	saveToIDB("entries", entry.linker, { ...entry, live: 0 });
	fetch(`${ApiUrl}/entry/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...entry,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateEntry(res.entry));
				saveToIDB("entries", res.entry.linker, res.entry);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*export const FetchEntrys = (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveEntrys(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateEntry(payload));
  }
};

export const addLiveEntry = (entry, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/entry/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...entry,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateEntry(res.entry));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveEntry = (entry, token, dispatch) => {
  return fetch(`${ApiUrl}/entry/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...entry,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateEntry(res.entry));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveEntrys = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/entry/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchEntrys(res.entrys));
      }
    })
    .catch(() => {});
};*/
