import React, { useState, useEffect } from "react";
import ModeCrud from "./ModeCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoMode from "./NoMode";
import { FetchModes } from "../../../Network/ModeApi";
import GeneratePdf from "../../../Utils/GeneratePdf";
import { useNavigate } from "react-router-dom";
const Modes = () => {
	useEffect(() => {
		FetchModes(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);
	const navigate = useNavigate();
	const [ShowModal, setShowModal] = useState(false);
	const [Mode, setMode] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();
	const inst = useSelector((state) => state.inst.activeInst);
	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Modes = useSelector((state) => state.mode.modes);

	const SearchMode = Modes.filter(
		(mode) =>
			(mode.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(mode.instLinker) === parseInt(ActiveCred.instLinker) &&
			parseInt(mode.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<div className="d-flex justify-content-around m-2">
				<p className="text-center h6 text-primary text-capitalize">
					Payment Modes
				</p>
				{inst.sub === "Kenya" ? (
					<button
						className="btn btn-sm  btn-success rounded-pill"
						onClick={() => navigate("mpesa-setup")}
					>
						M-Pesa Online
					</button>
				) : null}
			</div>
			<table className="table table-sm" id="modes">
				<thead className="thead-dark">
					<tr>
						<th scope="column" colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Payment Mode`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setMode({ name: "", details: "" });
								}}
							>
								<PlusCircle /> Add Payment Mode
							</button>
						</td>
					</tr>
				</thead>
				{SearchMode.length > 0 ? (
					<tbody>
						{SearchMode.map((mode, index) => (
							<tr key={index} className="item">
								<td>
									{mode.name}
									{mode.live != 1 ? (
										<small
											className="text-success align-text-bottom text"
											style={{ fontSize: 8 }}
										>
											<br />
											<em>offline</em>
										</small>
									) : null}
								</td>
								<td>{mode.details}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setMode({ ...mode });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setMode({ ...mode });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoMode></NoMode>
				)}
			</table>
			<GeneratePdf id={"modes"} name={"payment-modes"}></GeneratePdf>
			<ModeCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				mode={Mode}
				setMode={setMode}
				type={Type}
			></ModeCrud>
		</div>
	);
};

export default Modes;
