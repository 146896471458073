import swal from "sweetalert";
import {
	addPayrollCat,
	fetchPayrollCats,
	updatePayrollCat,
} from "../reducers/PayrollCatSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchPayrollCats = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"payrollcats",
		(payrollCat) => payrollCat.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchPayrollCats(data));
				FetchLivePayrollCats(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("payrollcats", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updatePayrollCat(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLivePayrollCats = (
	token,
	instLinker,
	online,
	dispatch,
	payrollCats
) => {
	fetch(`${ApiUrl}/payroll-categories/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.payrollCats.map((onlinePayrollCat) => {
					let localPayrollCat = payrollCats.find(
						(item) =>
							parseInt(item.linker) === parseInt(onlinePayrollCat.linker)
					);
					if (!localPayrollCat) {
						saveToIDB("payrollcats", onlinePayrollCat.linker, onlinePayrollCat);
						dispatch(updatePayrollCat(onlinePayrollCat));
					} else if (
						parseInt(localPayrollCat.trace) > parseInt(onlinePayrollCat.trace)
					) {
						updateLivePayrollCat(localPayrollCat, token, dispatch);
					} else if (
						parseInt(localPayrollCat.trace) < parseInt(onlinePayrollCat.trace)
					) {
						saveToIDB("payrollcats", onlinePayrollCat.linker, onlinePayrollCat);
						dispatch(updatePayrollCat(onlinePayrollCat));
					}
				});

				payrollCats.map((payrollCat) => {
					if (!payrollCat.id) {
						addLivePayrollCat(payrollCat, instLinker, token, dispatch);
					} else if (parseInt(payrollCat.live) === 0) {
						updateLivePayrollCat(payrollCat, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLivePayrollCat = (payrollCat, instLinker, token, dispatch) => {
	dispatch(updatePayrollCat({ ...payrollCat, live: 0 }));
	saveToIDB("payrollcats", payrollCat.linker, { ...payrollCat, live: 0 });
	fetch(`${ApiUrl}/payroll-categories/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollCat,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updatePayrollCat(res.payrollCat));
				saveToIDB("payrollcats", res.payrollCat.linker, res.payrollCat);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLivePayrollCat = (payrollCat, token, dispatch) => {
	dispatch(updatePayrollCat({ ...payrollCat, live: 0 }));
	saveToIDB("payrollcats", payrollCat.linker, { ...payrollCat, live: 0 });
	fetch(`${ApiUrl}/payroll-categories/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollCat,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updatePayrollCat(res.payrollCat));
				saveToIDB("payrollcats", res.payrollCat.linker, res.payrollCat);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*
export const FetchPayrollCats = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLivePayrollCats(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updatePayrollCat(payload));
	}
};

export const addLivePayrollCat = (payrollCat, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/payroll-categories/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollCat,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updatePayrollCat(res.payrollCat));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLivePayrollCat = (payrollCat, token, dispatch) => {
	return fetch(`${ApiUrl}/payroll-categories/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollCat,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updatePayrollCat(res.payrollCat));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLivePayrollCats = (token, instLinker, online, dispatch) => {
	return fetch(`${ApiUrl}/payroll-categories/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchPayrollCats(res.payrollCats));
			}
		})
		.catch(() => {});
};
*/
