import React, { useState, useEffect } from "react";
import ExpenseCatCrud from "./ExpenseCatCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoExpenseCats from "./NoExpenseCats";
import { FetchExpenseCats } from "../../../Network/ExpenseCatApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const ExpenseCats = () => {
	useEffect(() => {
		FetchExpenseCats(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [ExpenseCat, setExpenseCat] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const ExpenseCats = useSelector((state) => state.expenseCat.expenseCats);

	const SearchExpenseCats = ExpenseCats.filter(
		(mode) =>
			(mode.name?.toLowerCase().search(Search?.toLowerCase()) > -1 ||
				mode.details?.toLowerCase().search(Search?.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(mode.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				Expense Categories
			</p>
			<table className="table table-sm" id="expense-cats">
				<thead className="thead-dark">
					<tr>
						<th scope="column" colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Expense Category`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setExpenseCat({ name: "", details: "" });
								}}
							>
								<PlusCircle /> Expense Category
							</button>
						</td>
					</tr>
				</thead>
				{SearchExpenseCats.length > 0 ? (
					<tbody>
						{SearchExpenseCats.map((cat, index) => (
							<tr key={index} className="item">
								<td>
									{cat.name}
									{cat.live != 1 ? (
										<small
											className="text-success align-text-bottom text"
											style={{ fontSize: 8 }}
										>
											<br />
											<em>offline</em>
										</small>
									) : null}
								</td>
								<td>{cat.details}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setExpenseCat({ ...cat });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setExpenseCat({ ...cat });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoExpenseCats></NoExpenseCats>
				)}
			</table>
			<GeneratePdf id="expense-cats" name={"expense-categories"}></GeneratePdf>
			<ExpenseCatCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				expenseCat={ExpenseCat}
				setExpenseCat={setExpenseCat}
				type={Type}
			></ExpenseCatCrud>
		</div>
	);
};

export default ExpenseCats;
