import swal from "sweetalert";
import {
	addApplication,
	fetchApplications,
	updateApplication,
} from "../reducers/ApplicationSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchApplications = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"applications",
		(application) => application.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchApplications(data));
				FetchLiveApplications(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("applications", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateApplication(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveApplications = (
	token,
	instLinker,
	online,
	dispatch,
	applications
) => {
	fetch(`${ApiUrl}/application/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.applications.map((onlineApplication) => {
					let localApplication = applications.find(
						(item) =>
							parseInt(item.linker) === parseInt(onlineApplication.linker)
					);
					if (!localApplication) {
						saveToIDB(
							"applications",
							onlineApplication.linker,
							onlineApplication
						);
						dispatch(updateApplication(onlineApplication));
					} else if (
						parseInt(localApplication.trace) > parseInt(onlineApplication.trace)
					) {
						updateLiveApplication(localApplication, token, dispatch);
					} else if (
						parseInt(localApplication.trace) < parseInt(onlineApplication.trace)
					) {
						saveToIDB(
							"applications",
							onlineApplication.linker,
							onlineApplication
						);
						dispatch(updateApplication(onlineApplication));
					}
				});

				applications.map((application) => {
					if (!application.id) {
						addLiveApplication(application, instLinker, token, dispatch);
					} else if (parseInt(application.live) === 0) {
						updateLiveApplication(application, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveApplication = (
	application,
	instLinker,
	token,
	dispatch
) => {
	dispatch(updateApplication({ ...application, live: 0 }));
	saveToIDB("applications", application.linker, { ...application, live: 0 });
	fetch(`${ApiUrl}/application/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...application,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateApplication(res.application));
				saveToIDB("applications", res.application.linker, res.application);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveApplication = (application, token, dispatch) => {
	dispatch(updateApplication({ ...application, live: 0 }));
	saveToIDB("applications", application.linker, { ...application, live: 0 });
	fetch(`${ApiUrl}/application/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...application,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateApplication(res.application));
				saveToIDB("applications", res.application.linker, res.application);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};
/*export const FetchApplications = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveApplications(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateApplication(payload));
  }
};

export const addLiveApplication = (
  application,
  instLinker,
  token,
  dispatch
) => {
  return fetch(`${ApiUrl}/application/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...application,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateApplication(res.application));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveApplication = (application, token, dispatch) => {
  return fetch(`${ApiUrl}/application/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...application,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateApplication(res.application));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveApplications = (
  token,
  instLinker,
  online,
  dispatch,
  applications
) => {
  fetch(`${ApiUrl}/application/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchApplications(res.applications));
      }
    })
    .catch(() => {});
};*/
