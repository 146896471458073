import React, { useMemo, memo, useState } from "react";
import {
	Trash,
	PencilSquare,
	PatchPlus,
	PatchMinus,
	TicketDetailed,
	Envelope,
	CurrencyDollar,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Pay from "./Pay";

const ClientDisp = ({
	client,
	setClient,
	setShowModal,
	setType,
	setEntryModal,
	setEntry,
	setAccount,
	setSmsModal,
	Accounts,
	Summary,
	BFEntrys,
	CFEntrys,
	CurrentEntrys,
	SavingAccounts,
	LoanAccounts,
}) => {
	const Navigate = useNavigate();
	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);
	const [PayModal, setPayModal] = useState(false);
	const Branches = useSelector((state) => state.branch.branchs);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const ActiveInst = useSelector((state) => state.inst.activeInst);
	const Creds = useSelector((state) => state.cred.creds);
	/*const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);*/
	const Entrys = useSelector((state) => state.entry.entrys).filter(
		(entry) => parseInt(entry.deleted) === 0
	);

	const FindBranch = (branchLinker) => {
		let branch = Branches.find(
			(branch) => parseInt(branch.linker) === parseInt(branchLinker)
		);
		return branch || { name: "none" };
	};

	const FindCred = (credLinker) => {
		let cred = Creds.find(
			(cred) => parseInt(cred.linker) === parseInt(credLinker)
		);
		return cred || { email: "none" };
	};

	const FindGroup = (groupLinker) => {
		let group = Groups.find(
			(group) => parseInt(group.linker) === parseInt(groupLinker)
		);
		return group || { name: "none" };
	};

	const BFEntrysSum = useMemo(
		() =>
			BFEntrys.filter(
				(entry) => parseInt(client.linker) === parseInt(entry.clientLinker)
			).reduce((acc, current) => {
				const { accountLinker, type, amount } = current;
				const key = `${accountLinker}-${type}`;

				if (!acc[key]) {
					acc[key] = { accountLinker, type, totalAmount: 0 };
				}

				acc[key].totalAmount += +amount;

				return acc;
			}, {}),
		[BFEntrys]
	);

	const CFEntrysSum = useMemo(
		() =>
			CFEntrys.filter(
				(entry) => parseInt(client.linker) === parseInt(entry.clientLinker)
			).reduce((acc, current) => {
				const { accountLinker, type, amount } = current;
				const key = `${accountLinker}-${type}`;

				if (!acc[key]) {
					acc[key] = { accountLinker, type, totalAmount: 0 };
				}

				acc[key].totalAmount += +amount;

				return acc;
			}, {}),
		[CFEntrys]
	);

	const CurrentEntrysSum = useMemo(
		() =>
			CurrentEntrys.filter(
				(entry) => parseInt(client.linker) === parseInt(entry.clientLinker)
			).reduce((acc, current) => {
				const { accountLinker, type, amount } = current;
				const key = `${accountLinker}-${type}`;

				if (!acc[key]) {
					acc[key] = { accountLinker, type, totalAmount: 0 };
				}

				acc[key].totalAmount += +amount;

				return acc;
			}, {}),
		[CurrentEntrys]
	);

	const EntrysSum = useMemo(
		() =>
			Entrys.filter(
				(entry) => parseInt(client.linker) === parseInt(entry.clientLinker)
			).reduce((acc, current) => {
				const { accountLinker, type, amount } = current;
				const key = `${accountLinker}-${type}`;

				if (!acc[key]) {
					acc[key] = { accountLinker, type, totalAmount: 0 };
				}

				acc[key].totalAmount += +amount;

				return acc;
			}, {}),
		[Entrys]
	);

	return (
		<>
			<tr key={client.index}>
				<td>
					{" "}
					{client.clientReg}
					{client.live != 1 ? (
						<small
							className="text-success align-text-bottom text"
							style={{ fontSize: 8 }}
						>
							<br />
							<em>offline</em>
						</small>
					) : null}
				</td>
				<td className="d-flex justify-content-between">
					<Dropdown variant="transparent">
						<Dropdown.Toggle variant="transparent">
							{client.firstname}
						</Dropdown.Toggle>
						<Dropdown.Menu variant="transparent">
							<Dropdown.Item>
								<div className="d-flex justify-content-around">
									{ActiveInst.sub === "Kenya" ? (
										<span
											className="text-primary mx-1"
											onClick={() => {
												setSmsModal(true);
												setClient({ ...client });
											}}
											style={{ cursor: "pointer" }}
										>
											<Envelope />
											Sms
										</span>
									) : null}
									<span
										className="text-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setClient({ ...client });
										}}
										style={{ cursor: "pointer" }}
									>
										<PencilSquare />
										Edit
									</span>

									{parseInt(ActiveCred.admin) === 1 ? (
										<span
											className="text-danger mx-1"
											onClick={() => {
												setShowModal(true);
												setType("delete");
												setClient({ ...client });
											}}
											style={{ cursor: "pointer" }}
										>
											<Trash />
											Delete
										</span>
									) : null}
								</div>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Name : </strong>{" "}
									<em>
										{client.firstname} {client.lastname} {client.surname}
									</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Contact : </strong> <em>{client.contact}</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Email : </strong> <em>{client.email}</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Next of Kin Name : </strong> <em>{client.nokName}</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Next of Kin Contact : </strong>{" "}
									<em>{client.nokContact}</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Income : </strong> <em>{client.income}</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Location/Residence : </strong>{" "}
									<em>{client.location}</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Details : </strong> <em>{client.details}</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Registered By : </strong>{" "}
									<em>{FindCred(client.credLinker).email}</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Registered Branch : </strong>{" "}
									<em>{FindBranch(client.branchLinker).name}</em>
								</p>
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<strong>Registered Date : </strong>{" "}
									<em>
										{new Date(parseInt(client.linker)).toLocaleDateString(
											"en-US"
										)}
									</em>
								</p>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<div style={{ paddingTop: "5%" }}>
						<button
							className="btn btn-primary rounded m-0 p-0"
							onClick={() => setPayModal(true)}
						>
							<small>Pay</small>
						</button>
					</div>
				</td>

				{Groups.length > 0 ? (
					<td>{FindGroup(client.groupLinker).name}</td>
				) : null}
				{[...SavingAccounts, ...LoanAccounts].length < 1 ? (
					<td colSpan={3}>
						<p className="text-center h6">
							You Do Not Have Any Loan/Credit And Savings Accounts{" "}
							<em
								className="text-primary btn-link"
								style={{ cursor: "pointer" }}
								onClick={() => Navigate(`/${ActiveInst.name}/accounts`)}
							>
								Click Here To Add Them
							</em>
						</p>
					</td>
				) : null}
				{SavingAccounts.map((account) => (
					<td>
						<Dropdown variant="transparent">
							<Dropdown.Toggle
								variant="transparent"
								className="text-left m-0 p-0"
							>
								{Summary === "current"
									? (EntrysSum[`${account.linker}-saving`]?.totalAmount || 0) +
									  (EntrysSum[`${account.linker}-savings-interest`]
											?.totalAmount || 0) -
									  (EntrysSum[`${account.linker}-withdrawal`]?.totalAmount ||
											0) -
									  (EntrysSum[`${account.linker}-fine`]?.totalAmount || 0)
									: (BFEntrysSum[`${account.linker}-saving`]?.totalAmount ||
											0) +
									  (BFEntrysSum[`${account.linker}-savings-interest`]
											?.totalAmount || 0) -
									  (BFEntrysSum[`${account.linker}-withdrawal`]?.totalAmount ||
											0) -
									  (BFEntrysSum[`${account.linker}-fine`]?.totalAmount || 0)}
							</Dropdown.Toggle>
							<Dropdown.Menu variant="transparent">
								<Dropdown.Item
									className="text-success mx-1"
									onClick={() => {
										setEntryModal(true);
										setType("add");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
									style={{ cursor: "pointer" }}
								>
									<PatchPlus />
									Add
								</Dropdown.Item>
								<Dropdown.Item
									className="text-danger mx-1"
									onClick={() => {
										setEntryModal(true);
										setType("subtract");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
									style={{ cursor: "pointer" }}
								>
									<PatchMinus />
									Minus
								</Dropdown.Item>
								<Dropdown.Item
									className="text-primary mx-1"
									onClick={() => {
										window.scroll(0, 0);
										Navigate(
											`client/entries/${account.linker}/${client.linker}`
										);
									}}
									style={{ cursor: "pointer" }}
								>
									<TicketDetailed />
									Statement
								</Dropdown.Item>
								<Dropdown.Item
									className="text-primary mx-1"
									onClick={() => {
										window.scroll(0, 0);
										Navigate(
											`client/applications/${account.linker}/${client.linker}`
										);
									}}
									style={{ cursor: "pointer" }}
								>
									<Envelope />
									Applications
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</td>
				))}
				{LoanAccounts.map((account) => (
					<td>
						<Dropdown variant="transparent">
							<Dropdown.Toggle
								className="text-left m-0 p-0"
								variant="transparent"
							>
								{Summary === "current"
									? (EntrysSum[`${account.linker}-issued-loan`]?.totalAmount ||
											0) +
									  (EntrysSum[`${account.linker}-loan-interest`]
											?.totalAmount || 0) -
									  (EntrysSum[`${account.linker}-payment`]?.totalAmount || 0)
									: (BFEntrysSum[`${account.linker}-issued-loan`]
											?.totalAmount || 0) +
									  (BFEntrysSum[`${account.linker}-loan-interest`]
											?.totalAmount || 0) -
									  (BFEntrysSum[`${account.linker}-payment`]?.totalAmount ||
											0)}
							</Dropdown.Toggle>
							<Dropdown.Menu variant="transparent">
								<Dropdown.Item
									className="text-success mx-1"
									onClick={() => {
										setEntryModal(true);
										setType("add");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
									style={{ cursor: "pointer" }}
								>
									<PatchPlus />
									Add
								</Dropdown.Item>
								<Dropdown.Item
									className="text-danger mx-1"
									onClick={() => {
										setEntryModal(true);
										setType("subtract");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
									style={{ cursor: "pointer" }}
								>
									<PatchMinus />
									Minus
								</Dropdown.Item>
								<Dropdown.Item
									className="text-primary mx-1"
									onClick={() => {
										window.scroll(0, 0);
										Navigate(
											`client/entries/${account.linker}/${client.linker}`
										);
									}}
									style={{ cursor: "pointer" }}
								>
									<TicketDetailed />
									Statement
								</Dropdown.Item>
								<Dropdown.Item
									className="text-primary mx-1"
									onClick={() => {
										window.scroll(0, 0);
										Navigate(
											`client/applications/${account.linker}/${client.linker}`
										);
									}}
									style={{ cursor: "pointer" }}
								>
									<Envelope />
									Applications
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</td>
				))}
				{Summary !== "current" ? (
					<>
						{SavingAccounts.map((account) => (
							<>
								<td
									className="td-hover"
									onClick={() => {
										setEntryModal(true);
										setType("add");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "saving",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
								>
									{(CurrentEntrysSum[`${account.linker}-saving`]?.totalAmount ||
										0) +
										(CurrentEntrysSum[`${account.linker}-savings-interest`]
											?.totalAmount || 0)}
								</td>
								<td
									className="td-hover"
									onClick={() => {
										setEntryModal(true);
										setType("subtract");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "withdrawal",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
								>
									{(CurrentEntrysSum[`${account.linker}-withdrawal`]
										?.totalAmount || 0) +
										(CurrentEntrysSum[`${account.linker}-fine`]?.totalAmount ||
											0)}
								</td>
							</>
						))}
						{LoanAccounts.map((account) => (
							<>
								<td
									className="td-hover"
									onClick={() => {
										setEntryModal(true);
										setType("subtract");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "payment",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
								>
									{CurrentEntrysSum[`${account.linker}-payment`]?.totalAmount ||
										0}
								</td>{" "}
								<td
									className="td-hover"
									onClick={() => {
										setEntryModal(true);
										setType("add");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "issued-loan",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
								>
									{CurrentEntrysSum[`${account.linker}-issued-loan`]
										?.totalAmount || 0}
								</td>
								<td
									className="td-hover"
									onClick={() => {
										setEntryModal(true);
										setType("add");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "loan-interest",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
								>
									{CurrentEntrysSum[`${account.linker}-loan-interest`]
										?.totalAmount || 0}
								</td>
							</>
						))}
						{SavingAccounts.map((account) => (
							<td>
								<Dropdown variant="transparent">
									<Dropdown.Toggle
										variant="transparent"
										className="text-left m-0 p-0"
									>
										{(CFEntrysSum[`${account.linker}-saving`]?.totalAmount ||
											0) +
											(CFEntrysSum[`${account.linker}-savings-interest`]
												?.totalAmount || 0) -
											(CFEntrysSum[`${account.linker}-withdrawal`]
												?.totalAmount || 0) -
											(CFEntrysSum[`${account.linker}-fine`]?.totalAmount || 0)}
									</Dropdown.Toggle>
									<Dropdown.Menu variant="transparent">
										<Dropdown.Item
											className="text-success mx-1"
											onClick={() => {
												setEntryModal(true);
												setType("add");
												setClient(client);
												setAccount(account);
												setEntry({
													amount: "",
													details: "",
													security: "",
													guarantors: "",
													principal: "",
													period: "",
													type: "",
													accountLinker: account.linker,
													modeLinker: "",
													clientLinker: client.linker,
													code: "",
												});
											}}
											style={{ cursor: "pointer" }}
										>
											<PatchPlus />
											Add
										</Dropdown.Item>
										<Dropdown.Item
											className="text-danger mx-1"
											onClick={() => {
												setEntryModal(true);
												setType("subtract");
												setClient(client);
												setAccount(account);
												setEntry({
													amount: "",
													details: "",
													security: "",
													guarantors: "",
													principal: "",
													period: "",
													type: "",
													accountLinker: account.linker,
													modeLinker: "",
													clientLinker: client.linker,
													code: "",
												});
											}}
											style={{ cursor: "pointer" }}
										>
											<PatchMinus />
											Minus
										</Dropdown.Item>
										<Dropdown.Item
											className="text-primary mx-1"
											onClick={() => {
												window.scroll(0, 0);
												Navigate(
													`client/entries/${account.linker}/${client.linker}`
												);
											}}
											style={{ cursor: "pointer" }}
										>
											<TicketDetailed />
											Statement
										</Dropdown.Item>
										<Dropdown.Item
											className="text-primary mx-1"
											onClick={() => {
												window.scroll(0, 0);
												Navigate(
													`client/applications/${account.linker}/${client.linker}`
												);
											}}
											style={{ cursor: "pointer" }}
										>
											<Envelope />
											Applications
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</td>
						))}
						{LoanAccounts.map((account) => (
							<td>
								<Dropdown variant="transparent">
									<Dropdown.Toggle
										className="text-left m-0 p-0"
										variant="transparent"
									>
										{(CFEntrysSum[`${account.linker}-issued-loan`]
											?.totalAmount || 0) +
											(CFEntrysSum[`${account.linker}-loan-interest`]
												?.totalAmount || 0) -
											(CFEntrysSum[`${account.linker}-payment`]?.totalAmount ||
												0)}
									</Dropdown.Toggle>
									<Dropdown.Menu variant="transparent">
										<Dropdown.Item
											className="text-success mx-1"
											onClick={() => {
												setEntryModal(true);
												setType("add");
												setClient(client);
												setAccount(account);
												setEntry({
													amount: "",
													details: "",
													security: "",
													guarantors: "",
													principal: "",
													period: "",
													type: "",
													accountLinker: account.linker,
													modeLinker: "",
													clientLinker: client.linker,
													code: "",
												});
											}}
											style={{ cursor: "pointer" }}
										>
											<PatchPlus />
											Add
										</Dropdown.Item>
										<Dropdown.Item
											className="text-danger mx-1"
											onClick={() => {
												setEntryModal(true);
												setType("subtract");
												setClient(client);
												setAccount(account);
												setEntry({
													amount: "",
													details: "",
													security: "",
													guarantors: "",
													principal: "",
													period: "",
													type: "",
													accountLinker: account.linker,
													modeLinker: "",
													clientLinker: client.linker,
													code: "",
												});
											}}
											style={{ cursor: "pointer" }}
										>
											<PatchMinus />
											Minus
										</Dropdown.Item>
										<Dropdown.Item
											className="text-primary mx-1"
											onClick={() => {
												window.scroll(0, 0);
												Navigate(
													`client/entries/${account.linker}/${client.linker}`
												);
											}}
											style={{ cursor: "pointer" }}
										>
											<TicketDetailed />
											Statement
										</Dropdown.Item>
										<Dropdown.Item
											className="text-primary mx-1"
											onClick={() => {
												window.scroll(0, 0);
												Navigate(
													`client/applications/${account.linker}/${client.linker}`
												);
											}}
											style={{ cursor: "pointer" }}
										>
											<Envelope />
											Applications
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</td>
						))}
					</>
				) : null}
			</tr>{" "}
			<Pay
				Client={client}
				ShowModal={PayModal}
				setShowModal={setPayModal}
			></Pay>
		</>
	);
};

export default memo(ClientDisp);
