import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";

const ExpenseDisp = ({
	entry,
	setShowModal,
	setType,
	setEntry,
	setClient,
	setAccount,
}) => {
	const Creds = useSelector((state) => state.cred.creds);
	const Modes = useSelector((state) => state.mode.modes).filter(
		(mode) => parseInt(mode.deleted) === 0
	);
	const Branches = useSelector((state) => state.branch.branchs).filter(
		(branch) => parseInt(branch.deleted) === 0
	);
	const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);

	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);

	const FindUser = (linker) => {
		let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

		return cred || { firstname: "unknown" };
	};

	const FindMode = (linker) => {
		let mode = Modes.find((mode) => parseInt(mode.linker) === parseInt(linker));

		return mode || { name: "unknown" };
	};

	const FindAccount = (linker) => {
		let account = Accounts.find(
			(account) => parseInt(account.linker) === parseInt(linker)
		);

		return account || { name: "unknown" };
	};

	const FindGroup = (linker) => {
		let group = Groups.find(
			(group) => parseInt(group.linker) === parseInt(linker)
		);

		return group || { name: "unknown" };
	};

	const FindBranch = (linker) => {
		let branch = Branches.find(
			(branch) => parseInt(branch.linker) === parseInt(linker)
		);

		return branch || { name: "unknown" };
	};

	return (
		<tr className="item">
			<td>
				{FindMode(entry.modeLinker).name}
				{entry.live != 1 ? (
					<small
						className="text-success align-text-bottom text"
						style={{ fontSize: 8 }}
					>
						<br />
						<em>offline</em>
					</small>
				) : null}
			</td>
			<td>{FindAccount(entry.accountLinker).name}</td>
			<td>{entry.type}</td>
			{Branches.length > 0 ? (
				<td>{FindBranch(entry.branchLinker).name}</td>
			) : null}
			{Groups.length > 0 && !entry.total ? (
				<td>{FindGroup(FindUser(entry.clientLinker).groupLinker).name}</td>
			) : null}
			<td>{FindUser(entry.credLinker).email}</td>{" "}
			<td>{new Date(parseInt(entry.linker)).toLocaleDateString("en-US")}</td>
			<td>
				{entry.type === "issued-loan" ? (
					<Dropdown variant="transparent">
						<Dropdown.Toggle variant="transparent">
							{entry.code} {entry.details}
						</Dropdown.Toggle>
						<Dropdown.Menu variant="transparent">
							<Dropdown.Item>
								<p>
									<stron>Principal : </stron> {entry.principal}
								</p>
								<hr />
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<stron>Duration : </stron> {entry.period}
								</p>
								<hr />
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<stron>Guarantors : </stron> {entry.guarantors}
								</p>
								<hr />
							</Dropdown.Item>
							<Dropdown.Item>
								<p>
									<stron>Security : </stron> {entry.security}
								</p>
								<hr />
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<>
						{entry.code} {entry.details}
					</>
				)}
			</td>
			<td>
				{!entry.total ? (
					entry.amount
				) : entry.type === "withdrawal" ||
				  entry.type === "fine" ||
				  entry.type === "payment" ? (
					<span className="text-primary">- {entry.amount}</span>
				) : (
					<span className="text-success">+ {entry.amount}</span>
				)}
			</td>
			{entry.total ? <td>{entry.total}</td> : null}
			<td>
				<PencilSquare
					onClick={() => {
						setClient(FindUser(entry.clientLinker));
						setAccount(FindAccount(entry.accountLinker));
						setShowModal(true);
						setType("edit");
						setEntry({ ...entry });
					}}
					style={{ cursor: "pointer" }}
					className="text-info mx-2"
				/>

				<Trash
					onClick={() => {
						setClient(FindUser(entry.clientLinker));
						setAccount(FindAccount(entry.accountLinker));
						setShowModal(true);
						setType("delete");
						setEntry({ ...entry });
					}}
					style={{ cursor: "pointer" }}
					className="text-danger mx-2"
				/>
			</td>
		</tr>
	);
};

export default ExpenseDisp;
