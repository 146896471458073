import swal from "sweetalert";
import {
	fetchPayrollEntries,
	updatePayrollEntry,
} from "../reducers/PayrollEntrySlice";
import { ApiUrl } from "./Urls";
import { fetchStaffPayEntries } from "../reducers/StaffPayEntrySlice";

import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchPayrollEntries = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"payrollEntries",
		(payrollEntry) => payrollEntry.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchPayrollEntries(data));
				FetchLivePayrollEntries(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("payrollEntries", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updatePayrollEntry(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLivePayrollEntries = (
	token,
	instLinker,
	online,
	dispatch,
	payrollEntries
) => {
	fetch(`${ApiUrl}/payroll-entries/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.payrollEntries.map((onlinePayrollEntry) => {
					let localPayrollEntry = payrollEntries.find(
						(item) =>
							parseInt(item.linker) === parseInt(onlinePayrollEntry.linker)
					);
					if (!localPayrollEntry) {
						saveToIDB(
							"payrollEntries",
							onlinePayrollEntry.linker,
							onlinePayrollEntry
						);
						dispatch(updatePayrollEntry(onlinePayrollEntry));
					} else if (
						parseInt(localPayrollEntry.trace) >
						parseInt(onlinePayrollEntry.trace)
					) {
						updateLivePayrollEntry(localPayrollEntry, token, dispatch);
					} else if (
						parseInt(localPayrollEntry.trace) <
						parseInt(onlinePayrollEntry.trace)
					) {
						saveToIDB(
							"payrollEntries",
							onlinePayrollEntry.linker,
							onlinePayrollEntry
						);
						dispatch(updatePayrollEntry(onlinePayrollEntry));
					}
				});

				payrollEntries.map((payrollEntry) => {
					if (!payrollEntry.id) {
						addLivePayrollEntry(payrollEntry, instLinker, token, dispatch);
					} else if (parseInt(payrollEntry.live) === 0) {
						updateLivePayrollEntry(payrollEntry, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLivePayrollEntry = (
	payrollEntry,
	instLinker,
	token,
	dispatch
) => {
	dispatch(updatePayrollEntry({ ...payrollEntry, live: 0 }));
	saveToIDB("payrollEntries", payrollEntry.linker, {
		...payrollEntry,
		live: 0,
	});
	fetch(`${ApiUrl}/payroll-entries/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollEntry,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updatePayrollEntry(res.payrollEntry));
				saveToIDB("payrollEntries", res.payrollEntry.linker, res.payrollEntry);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLivePayrollEntry = (payrollEntry, token, dispatch) => {
	dispatch(updatePayrollEntry({ ...payrollEntry, live: 0 }));
	saveToIDB("payrollEntries", payrollEntry.linker, {
		...payrollEntry,
		live: 0,
	});
	fetch(`${ApiUrl}/payroll-entries/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollEntry,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updatePayrollEntry(res.payrollEntry));
				saveToIDB("payrollEntries", res.payrollEntry.linker, res.payrollEntry);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

export const StaffLivePayrollEntries = (
	token,
	instLinker,
	online,
	dispatch
) => {
	return fetch(`${ApiUrl}/payroll-entries/staff`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchStaffPayEntries(res.payrollEntries));
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

/*

export const FetchPayrollEntries = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		return await FetchLivePayrollEntries(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updatePayrollEntry(payload));
	}
};

export const addLivePayrollEntry = (
	payrollEntry,
	instLinker,
	token,
	dispatch,
	setLoading,
	InputItems,
	index,
	setShowModal
) => {
	return fetch(`${ApiUrl}/payroll-entries/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollEntry,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then(async (res) => {
			if (res.status === 201) {
				dispatch(updatePayrollEntry(res.payrollEntry));
			}

			if (index === InputItems.length - 1) {
				await FetchLivePayrollEntries(token, instLinker, 0, dispatch);
				setLoading(false);
				setShowModal(false);
				swal({
					title: "PaySlips Generated Successfully",
					icon: "success",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLivePayrollEntry = (payrollEntry, token, dispatch) => {
	return fetch(`${ApiUrl}/payroll-entries/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollEntry,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updatePayrollEntry(res.payrollEntry));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLivePayrollEntries = async (
	token,
	instLinker,
	online,
	dispatch
) => {
	return await fetch(`${ApiUrl}/payroll-entries/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchPayrollEntries(res.payrollEntries));
			}
		})
		.catch((err) => {
			console.log(err);
		});
};


*/
