import swal from "sweetalert";
import {
	addPayrollItem,
	fetchPayrollItems,
	updatePayrollItem,
} from "../reducers/PayrollItemSlice";
import { ApiUrl } from "./Urls";
import { fetchStaffPayItems } from "../reducers/StaffPayItemSlice";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchPayrollItems = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"payrollItems",
		(payrollItem) => payrollItem.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchPayrollItems(data));
				FetchLivePayrollItems(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("payrollItems", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updatePayrollItem(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLivePayrollItems = (
	token,
	instLinker,
	online,
	dispatch,
	payrollItems
) => {
	fetch(`${ApiUrl}/payroll-items/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.payrollItems.map((onlinePayrollItem) => {
					let localPayrollItem = payrollItems.find(
						(item) =>
							parseInt(item.linker) === parseInt(onlinePayrollItem.linker)
					);
					if (!localPayrollItem) {
						saveToIDB(
							"payrollItems",
							onlinePayrollItem.linker,
							onlinePayrollItem
						);
						dispatch(updatePayrollItem(onlinePayrollItem));
					} else if (
						parseInt(localPayrollItem.trace) > parseInt(onlinePayrollItem.trace)
					) {
						updateLivePayrollItem(localPayrollItem, token, dispatch);
					} else if (
						parseInt(localPayrollItem.trace) < parseInt(onlinePayrollItem.trace)
					) {
						saveToIDB(
							"payrollItems",
							onlinePayrollItem.linker,
							onlinePayrollItem
						);
						dispatch(updatePayrollItem(onlinePayrollItem));
					}
				});

				payrollItems.map((payrollItem) => {
					if (!payrollItem.id) {
						addLivePayrollItem(payrollItem, instLinker, token, dispatch);
					} else if (parseInt(payrollItem.live) === 0) {
						updateLivePayrollItem(payrollItem, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLivePayrollItem = (
	payrollItem,
	instLinker,
	token,
	dispatch
) => {
	dispatch(updatePayrollItem({ ...payrollItem, live: 0 }));
	saveToIDB("payrollItems", payrollItem.linker, { ...payrollItem, live: 0 });
	fetch(`${ApiUrl}/payroll-items/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollItem,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updatePayrollItem(res.payrollItem));
				saveToIDB("payrollItems", res.payrollItem.linker, res.payrollItem);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLivePayrollItem = (payrollItem, token, dispatch) => {
	dispatch(updatePayrollItem({ ...payrollItem, live: 0 }));
	saveToIDB("payrollItems", payrollItem.linker, { ...payrollItem, live: 0 });
	fetch(`${ApiUrl}/payroll-items/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollItem,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updatePayrollItem(res.payrollItem));
				saveToIDB("payrollItems", res.payrollItem.linker, res.payrollItem);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};
export const StaffLivePayrollItems = (token, instLinker, online, dispatch) => {
	return fetch(`${ApiUrl}/payroll-items/staff`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchStaffPayItems(res.payrollItems));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};
/*
export const FetchPayrollItems = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLivePayrollItems(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updatePayrollItem(payload));
	}
};

export const addLivePayrollItem = (
	payrollItem,
	instLinker,
	token,
	dispatch
) => {
	return fetch(`${ApiUrl}/payroll-items/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollItem,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updatePayrollItem(res.payrollItem));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLivePayrollItem = (payrollItem, token, dispatch) => {
	return fetch(`${ApiUrl}/payroll-items/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payrollItem,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updatePayrollItem(res.payrollItem));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLivePayrollItems = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/payroll-items/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchPayrollItems(res.payrollItems));
			}
		})
		.catch(() => {});
};

export const StaffLivePayrollItems = (token, instLinker, online, dispatch) => {
	return fetch(`${ApiUrl}/payroll-items/staff`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchStaffPayItems(res.payrollItems));
			}
		})
		.catch(() => {});
*/
