import swal from "sweetalert";
import {
	addExpenseCat,
	fetchExpenseCats,
	updateExpenseCat,
} from "../reducers/ExpenseCatSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchExpenseCats = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"expensecats",
		(expenseCats) => expenseCats.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchExpenseCats(data));
				FetchLiveExpenseCats(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("expensecats", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateExpenseCat(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveExpenseCats = (
	token,
	instLinker,
	online,
	dispatch,
	expenseCats
) => {
	fetch(`${ApiUrl}/expense-categories/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.expenseCats.map((onlineExpenseCat) => {
					let localExpenseCat = expenseCats.find(
						(item) =>
							parseInt(item.linker) === parseInt(onlineExpenseCat.linker)
					);
					if (!localExpenseCat) {
						saveToIDB("expensecats", onlineExpenseCat.linker, onlineExpenseCat);
						dispatch(updateExpenseCat(onlineExpenseCat));
					} else if (
						parseInt(localExpenseCat.trace) > parseInt(onlineExpenseCat.trace)
					) {
						updateLiveExpenseCat(localExpenseCat, token, dispatch);
					} else if (
						parseInt(localExpenseCat.trace) < parseInt(onlineExpenseCat.trace)
					) {
						saveToIDB("expensecats", onlineExpenseCat.linker, onlineExpenseCat);
						dispatch(updateExpenseCat(onlineExpenseCat));
					}
				});

				expenseCats.map((expenseCat) => {
					if (!expenseCat.id) {
						addLiveExpenseCat(expenseCat, instLinker, token, dispatch);
					} else if (parseInt(expenseCat.live) === 0) {
						updateLiveExpenseCat(expenseCat, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveExpenseCat = (expenseCat, instLinker, token, dispatch) => {
	dispatch(updateExpenseCat({ ...expenseCat, live: 0 }));
	saveToIDB("expensecats", expenseCat.linker, { ...expenseCat, live: 0 });
	fetch(`${ApiUrl}/expense-categories/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...expenseCat,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateExpenseCat(res.expenseCat));
				saveToIDB("expensecats", res.expenseCat.linker, res.expenseCat);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveExpenseCat = (expenseCat, token, dispatch) => {
	dispatch(updateExpenseCat({ ...expenseCat, live: 0 }));
	saveToIDB("expensecats", expenseCat.linker, { ...expenseCat, live: 0 });
	fetch(`${ApiUrl}/expense-categories/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...expenseCat,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateExpenseCat(res.expenseCat));
				saveToIDB("expensecats", res.expenseCat.linker, res.expenseCat);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};
/*
export const FetchExpenseCats = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveExpenseCats(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateExpenseCat(payload));
	}
};

export const addLiveExpenseCat = (expenseCat, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/expense-categories/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...expenseCat,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateExpenseCat(res.expenseCat));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveExpenseCat = (expenseCat, token, dispatch) => {
	return fetch(`${ApiUrl}/expense-categories/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...expenseCat,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateExpenseCat(res.expenseCat));
			}
		})
		.catch((err) => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
			return { status: "error" };
		});
};

export const FetchLiveExpenseCats = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/expense-categories/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchExpenseCats(res.expenseCats));
			}
		})
		.catch(() => {});
};*/
