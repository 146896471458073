import React, { useState, useEffect } from "react";
import AccountCrud from "./AccountCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoAccount from "./NoAccount";
import { FetchAccounts } from "../../../Network/AccountApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Accounts = () => {
	useEffect(() => {
		FetchAccounts(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [Account, setAccount] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Accounts = useSelector((state) => state.account.accounts);
	const inst = useSelector((state) => state.inst.activeInst);

	const SearchAccount = Accounts.filter(
		(account) =>
			(account.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(account.instLinker) === parseInt(ActiveCred.instLinker) &&
			parseInt(account.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				{SearchAccount.length} Accounts
			</p>
			<table className="table table-sm" id="accounts">
				<thead className="thead-dark">
					<tr>
						<th scope="column" colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Account`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						{inst.sub === "Kenya" ? (
							<th>
								Code <br />{" "}
								<span className="text-center">
									<em>
										<small>{`{Mpesa Code To Be Added At The End Of Clients Acc On Paybill Payment}`}</small>
									</em>
								</span>
							</th>
						) : null}
						<th>Type</th>
						<th>Int-Type</th>
						<th>Int-Rate</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setAccount({
										name: "",
										details: "",
										type: "",
										rate: 0,
										status: "",
									});
								}}
							>
								<PlusCircle /> Add Account
							</button>
						</td>
					</tr>
				</thead>
				{SearchAccount.length > 0 ? (
					<tbody>
						{SearchAccount.map((account, index) => (
							<tr key={index} className="item">
								<td>
									{account.name}
									{account.live != 1 ? (
										<small
											className="text-success align-text-bottom text"
											style={{ fontSize: 8 }}
										>
											<br />
											<em>offline</em>
										</small>
									) : null}
								</td>
								<td>{account.details}</td>{" "}
								{inst.sub === "Kenya" ? (
									<td className="text-center">
										<strong>
											{account.name.substring(0, 2).toUpperCase()}
										</strong>
									</td>
								) : null}
								<td>{account.status}</td>
								<td>{account.type}</td>
								<td>{account.rate}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setAccount({ ...account });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setAccount({ ...account });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoAccount></NoAccount>
				)}
			</table>
			<GeneratePdf id={"accounts"} name={"accounts"}></GeneratePdf>
			<AccountCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				account={Account}
				setAccount={setAccount}
				type={Type}
			></AccountCrud>
		</div>
	);
};

export default Accounts;
