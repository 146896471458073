import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { ApiUrl } from "../../Network/Urls";
import { fetchEntrys, updateEntry } from "../../reducers/EntrySlice";
import { saveToIDB } from "../../Utils/IdbHelper";

const Pay = ({ Client, ShowModal, setShowModal }) => {
	//redux dispatch
	const dispatch = useDispatch();
	useEffect(() => {
		setInputs(
			[...SavingAccounts, ...LoanAccounts].map((account) => ({
				...account,
				amount: 0,
				type: account.status === "saving" ? "saving" : "payment",
				accountLinker: account.linker,
				clientLinker: Client.linker,
			}))
		);
	}, [Client.linker]);
	const inst = useSelector((state) => state.inst.activeInst);
	const User = useSelector((state) => state.auth.user);
	const Entries = useSelector((state) => state.entry.entrys).filter(
		(entry) => parseInt(entry.deleted) === 0
	);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Modes = useSelector((state) => state.mode.modes).filter(
		(mode) => parseInt(mode.deleted) === 0
	);
	const [Loading, setLoading] = useState(false);
	const AllAccounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);
	const LoanAccounts = useMemo(
		() => AllAccounts.filter((account) => account.status === "loan"),
		[AllAccounts]
	);
	const SavingAccounts = useMemo(
		() => AllAccounts.filter((account) => account.status === "saving"),
		[AllAccounts]
	);
	const [TotalAmount, setTotalAmount] = useState(0);
	const [Details, setDetails] = useState("");
	const [Code, setCode] = useState("");
	const [Mode, setMode] = useState("");
	const [Inputs, setInputs] = useState([]);

	const UpdateAmount = (value, index) => {
		let NewInputs = [...Inputs];
		NewInputs[index].amount = value;
		setInputs(NewInputs);
	};

	const HandleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		let trace = Date.now();
		try {
			swal({
				title: "Transactions Will Be Saved!!",
				text: `If You Are Okay Continue`,
				icon: "warning",
				showCancelButton: true,
				buttons: ["Cancel Save", "Continue"],
			}).then(async (isConfirmed) => {
				if (isConfirmed) {
					// Map over inputs and create a fetch promise for each input
					const requests = Inputs.filter(
						(input) => input.amount > 0 && !/^\s*$/.test(input.amount)
					).map(
						(input, index) => {
							delete input.id;
							dispatch(
								updateEntry({
									...input,
									instLinker: inst.linker,
									linker: trace + index,
									trace: trace + index,
									details: Details,
									code: Code,
									modeLinker: Mode,
									live: 0,
									id: null,
								})
							);
							saveToIDB("entries", trace + index, {
								...input,
								instLinker: inst.linker,
								linker: trace + index,
								trace: trace + index,
								details: Details,
								code: Code,
								modeLinker: Mode,
								live: 0,
								id: null,
							});
							fetch(`${ApiUrl}/entry/add`, {
								method: "POST",
								headers: {
									"content-type": "application/json",
									authorization: `bearer ${User.token}`,
								},
								body: JSON.stringify({
									...input,
									instLinker: inst.linker,
									linker: trace + index,
									trace: trace + index,
									details: Details,
									code: Code,
									modeLinker: Mode,
								}),
							})
								.then((res) => res.json())
								.catch((err) => ({ err }));
						} // Parse JSON from each response
					);

					// Use Promise.all to wait for all requests to complete
					let Responses = await Promise.all(requests);
					let successResponses = Responses.filter(
						(response) => response.status === 201
					).map((response) => response.entry);
					dispatch(fetchEntrys([...successResponses, ...Entries]));
				} else {
				}
			});
		} catch (error) {
		} finally {
			setLoading(false);
			setShowModal(false);
		}
	};
	return (
		<Modal
			show={ShowModal}
			onHide={() => setShowModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<div>
						<p className="text-capitalize text-center m-0 p-0">
							{Client.clientReg} {Client.firstname} {Client.lastname}{" "}
							{Client.surname} Pay
						</p>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					<div className="form-group">
						<label className="text-capitalize">Total Amount:</label>
						<input
							value={TotalAmount}
							onChange={(e) => setTotalAmount(e.target.value)}
							placeholder={`Enter Total Amount`}
							required
							className="form-control rounded"
							type="number"
						/>{" "}
						<hr />
					</div>
					{Modes.length > 0 ? (
						<div className="form-group">
							<label className=" text-capitalize">Mode Of Payment:</label>
							<select
								className="rounded form-control"
								value={Mode}
								onChange={(e) => setMode(e.target.value)}
								required
							>
								<option value={""}>Select Payment Mode</option>
								{Modes.map((mode, index) => (
									<option key={index} value={mode.linker}>
										{mode.name}
									</option>
								))}
							</select>
							<hr />
						</div>
					) : null}
					<div className="form-group">
						<label className="text-capitalize">
							Transaction Code/ Receipt No:
						</label>
						<input
							value={Code}
							onChange={(e) => setCode(e.target.value)}
							placeholder={`Enter Transaction Code`}
							required
							className="form-control rounded"
							type="text"
						/>{" "}
						<hr />
					</div>
					<div className="form-group">
						<label className="text-capitalize">Transaction Details:</label>
						<textarea
							value={Details}
							onChange={(e) => setDetails(e.target.value)}
							placeholder={`Enter Transaction Details`}
							required
							className="form-control rounded"
							type="text"
						/>{" "}
						<hr />
					</div>
					{Inputs.map((item, index) => (
						<>
							{SavingAccounts.length === index ? (
								<div>
									<p className="text-center">
										<strong>
											NB :{" "}
											<em className="text-center text-decoration-underline">
												For Loans Or Credit A/Cs Add Total Amount System Will
												Deduct Interest Where Applicable
											</em>
										</strong>
									</p>
									<hr />
								</div>
							) : null}
							<div className="form-group">
								<label className="text-capitalize">{item.name} Amount:</label>
								<input
									value={item.amount}
									onChange={(e) => UpdateAmount(e.target.value, index)}
									placeholder={`Enter ${item.name} Amount`}
									required
									className="form-control rounded"
								/>{" "}
								<hr />
							</div>{" "}
						</>
					))}

					<div className="d-flex justify-content-around mb-2">
						{Loading ? (
							<span className="spinner-border text-primary"></span>
						) : (
							<Button variant="primary" type="submit">
								Save
							</Button>
						)}
						<Button
							variant="secondary"
							type="button"
							onClick={() => setShowModal(false)}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};
export default Pay;
