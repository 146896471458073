import swal from "sweetalert";
import { addGroup, fetchGroups, updateGroup } from "../reducers/GroupSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchGroups = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("groups", (group) => group.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchGroups(data));
				FetchLiveGroups(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("groups", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateGroup(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveGroups = (
	token,
	instLinker,
	online,
	dispatch,
	groups
) => {
	fetch(`${ApiUrl}/group/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.groups.map((onlineGroup) => {
					let localGroup = groups.find(
						(item) => parseInt(item.linker) === parseInt(onlineGroup.linker)
					);
					if (!localGroup) {
						saveToIDB("groups", onlineGroup.linker, onlineGroup);
						dispatch(updateGroup(onlineGroup));
					} else if (parseInt(localGroup.trace) > parseInt(onlineGroup.trace)) {
						updateLiveGroup(localGroup, token, dispatch);
					} else if (parseInt(localGroup.trace) < parseInt(onlineGroup.trace)) {
						saveToIDB("groups", onlineGroup.linker, onlineGroup);
						dispatch(updateGroup(onlineGroup));
					}
				});

				groups.map((group) => {
					if (!group.id) {
						addLiveGroup(group, instLinker, token, dispatch);
					} else if (parseInt(group.live) === 0) {
						updateLiveGroup(group, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveGroup = (group, instLinker, token, dispatch) => {
	dispatch(updateGroup({ ...group, live: 0 }));
	saveToIDB("groups", group.linker, { ...group, live: 0 });
	fetch(`${ApiUrl}/group/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...group,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateGroup(res.group));
				saveToIDB("groups", res.group.linker, res.group);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveGroup = (group, token, dispatch) => {
	dispatch(updateGroup({ ...group, live: 0 }));
	saveToIDB("groups", group.linker, { ...group, live: 0 });
	fetch(`${ApiUrl}/group/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...group,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateGroup(res.group));
				saveToIDB("groups", res.group.linker, res.group);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};
/*export const FetchGroups = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveGroups(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateGroup(payload));
  }
};

export const addLiveGroup = (group, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/group/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...group,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateGroup(res.group));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveGroup = (group, token, dispatch) => {
  return fetch(`${ApiUrl}/group/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...group,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateGroup(res.group));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveGroups = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/group/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchGroups(res.groups));
      }
    })
    .catch(() => {});
};*/
