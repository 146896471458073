import React, { useState, useEffect } from "react";
import CatCrud from "./CatCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoCat from "./NoCats";
import { FetchPayrollCats } from "../../../../Network/PayrollCatApi";
import GeneratePdf from "../../../../Utils/GeneratePdf";

const Cats = () => {
	useEffect(() => {
		FetchPayrollCats(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [Cat, setCat] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();

	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Cats = useSelector((state) => state.payrollCat.payrollCats);

	const SearchCat = Cats.filter(
		(cat) =>
			(cat.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(cat.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				Payroll Items
			</p>
			<table className="table table-sm" id="payroll-items">
				<thead className="thead-dark">
					<tr>
						<th scope="column" colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Payroll Item`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setCat({ name: "", type: "" });
								}}
							>
								<PlusCircle /> Add Payroll Item
							</button>
						</td>
					</tr>
				</thead>
				{SearchCat.length > 0 ? (
					<tbody>
						{SearchCat.map((cat, index) => (
							<tr key={index} className="item">
								<td>
									{cat.name}
									{cat.live != 1 ? (
										<small
											className="text-success align-text-bottom text"
											style={{ fontSize: 8 }}
										>
											<br />
											<em>offline</em>
										</small>
									) : null}
								</td>
								<td>{cat.type}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setCat({ ...cat });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setCat({ ...cat });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoCat></NoCat>
				)}
			</table>
			<GeneratePdf id={"payroll-items"} name={"payroll-items"}></GeneratePdf>
			<CatCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				cat={Cat}
				setCat={setCat}
				type={Type}
			></CatCrud>
		</div>
	);
};

export default Cats;
