import React, { useState, useEffect } from "react";
import GroupCrud from "./GroupCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoGroup from "./NoGroup";
import { FetchGroups } from "../../../Network/GroupApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Groups = () => {
	useEffect(() => {
		FetchGroups(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [Group, setGroup] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Groups = useSelector((state) => state.group.groups);

	const SearchGroup = Groups.filter(
		(group) =>
			(group.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(group.instLinker) === parseInt(ActiveCred.instLinker) &&
			parseInt(group.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				Institution Groups
			</p>
			<table className="table table-sm" id="groups">
				<thead className="thead-dark">
					<tr>
						<th scope="column" colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Group`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setGroup({ name: "", details: "" });
								}}
							>
								<PlusCircle /> Add Group
							</button>
						</td>
					</tr>
				</thead>
				{SearchGroup.length > 0 ? (
					<tbody>
						{SearchGroup.map((group, index) => (
							<tr key={index} className="item">
								<td>
									{group.name}
									{group.live != 1 ? (
										<small
											className="text-success align-text-bottom text"
											style={{ fontSize: 8 }}
										>
											<br />
											<em>offline</em>
										</small>
									) : null}
								</td>
								<td>{group.details}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setGroup({ ...group });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setGroup({ ...group });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoGroup></NoGroup>
				)}
			</table>
			<GeneratePdf id={"groups"} name={"groups"}></GeneratePdf>
			<GroupCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				group={Group}
				setGroup={setGroup}
				type={Type}
			></GroupCrud>
		</div>
	);
};

export default Groups;
