import swal from "sweetalert";
import {
	addAccount,
	fetchAccounts,
	updateAccount,
} from "../reducers/AccountSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchAccounts = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("accounts", (account) => account.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchAccounts(data));
				FetchLiveAccounts(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("accounts", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateAccount(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveAccounts = (
	token,
	instLinker,
	online,
	dispatch,
	accounts
) => {
	fetch(`${ApiUrl}/account/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.accounts.map((onlineAccount) => {
					let localAccount = accounts.find(
						(item) => parseInt(item.linker) === parseInt(onlineAccount.linker)
					);
					if (!localAccount) {
						saveToIDB("accounts", onlineAccount.linker, onlineAccount);
						dispatch(updateAccount(onlineAccount));
					} else if (
						parseInt(localAccount.trace) > parseInt(onlineAccount.trace)
					) {
						updateLiveAccount(localAccount, token, dispatch);
					} else if (
						parseInt(localAccount.trace) < parseInt(onlineAccount.trace)
					) {
						saveToIDB("accounts", onlineAccount.linker, onlineAccount);
						dispatch(updateAccount(onlineAccount));
					}
				});

				accounts.map((account) => {
					if (!account.id) {
						addLiveAccount(account, instLinker, token, dispatch);
					} else if (parseInt(account.live) === 0) {
						updateLiveAccount(account, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveAccount = (account, instLinker, token, dispatch) => {
	dispatch(updateAccount({ ...account, live: 0 }));
	saveToIDB("accounts", account.linker, { ...account, live: 0 });
	fetch(`${ApiUrl}/account/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...account,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateAccount(res.account));
				saveToIDB("accounts", res.account.linker, res.account);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveAccount = (account, token, dispatch) => {
	dispatch(updateAccount({ ...account, live: 0 }));
	saveToIDB("accounts", account.linker, { ...account, live: 0 });
	fetch(`${ApiUrl}/account/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...account,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateAccount(res.account));
				saveToIDB("accounts", res.account.linker, res.account);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*export const FetchAccounts = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveAccounts(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateAccount(payload));
  }
};

export const addLiveAccount = (account, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/account/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...account,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateAccount(res.account));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveAccount = (account, token, dispatch) => {
  return fetch(`${ApiUrl}/account/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...account,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateAccount(res.account));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveAccounts = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/account/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchAccounts(res.accounts));
      }
    })
    .catch(() => {});
};*/
