import swal from "sweetalert";
import {
	addCred,
	fetchCreds,
	setActiveCred,
	updateCred,
} from "../reducers/CredSlice";
import { ApiUrl } from "./Urls";
import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const RegisterLiveCred = (staff, link, token, dispatch) => {
	dispatch(updateCred({ ...staff, live: 0 }));
	saveToIDB("credentials", staff.linker, { ...staff, live: 0 });
	fetch(`${ApiUrl}/${link}`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...staff,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateCred(res.cred));
				saveToIDB("credentials", res.cred.linker, res.cred);
				if (link === "staff/super") {
					dispatch(setActiveCred(res.cred));
				}
			} else {
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const FetchStaffs = async (
	instLinker,
	token,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("credentials", (staff) => staff.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchCreds(data));
				FetchLiveCreds(token, instLinker, online, dispatch, "staff/get", data);
			} else {
				saveToIDB("credentials", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateCred(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchClients = async (
	instLinker,
	token,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB(
		"credentials",
		(student) => student.instLinker == instLinker
	)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchCreds(data));
				FetchLiveCreds(token, instLinker, online, dispatch, "member/get", data);
			} else {
				saveToIDB("credentials", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateCred(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const updateLiveCred = (staff, link, token, dispatch) => {
	dispatch(updateCred({ ...staff, live: 0 }));
	saveToIDB("credentials", staff.linker, { ...staff, live: 0 });
	fetch(`${ApiUrl}/${link}`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...staff,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateCred(res.cred));
				saveToIDB("credentials", res.cred.linker, res.cred);
			} else {
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {
			return { status: "error" };
		});
};

export const FetchLiveCreds = (
	token,
	instLinker,
	online,
	dispatch,
	link,
	creds
) => {
	fetch(`${ApiUrl}/${link}`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.creds.map((onlineCred) => {
					let localCred = creds.find(
						(item) => parseInt(item.linker) === parseInt(onlineCred.linker)
					);
					if (!localCred) {
						saveToIDB("credentials", onlineCred.linker, onlineCred);
						dispatch(updateCred(onlineCred));
					} else if (parseInt(localCred.trace) > parseInt(onlineCred.trace)) {
						updateLiveCred(
							localCred,
							`${link.split("/")[0]}/edit`,
							token,
							dispatch
						);
					} else if (parseInt(localCred.trace) < parseInt(onlineCred.trace)) {
						saveToIDB("credentials", onlineCred.linker, onlineCred);
						dispatch(updateCred(onlineCred));
					}
				});

				creds.map((cred) => {
					if (!cred.id) {
						RegisterLiveCred(
							cred,
							`${cred.client == 1 ? "member" : "staff"}/add`,
							token,
							dispatch
						);
					} else if (parseInt(cred.live) === 0) {
						updateLiveCred(cred, `${link.split("/")[0]}/edit`, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const FetchUserLiveCreds = (token) => {
	return fetch(`${ApiUrl}/auth/creds`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				return res;
			} else {
				return 0;
			}
		})
		.catch((err) => {
			return { status: "error" };
		});
};
