import swal from "sweetalert";
import { addMode, fetchModes, updateMode } from "../reducers/ModeSlice";
import { ApiUrl } from "./Urls";

import { getFilteredFromIDB, saveToIDB } from "../Utils/IdbHelper";

export const FetchModes = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	getFilteredFromIDB("modes", (mode) => mode.instLinker == instLinker)
		.then((data) => {
			if (from === "fetch") {
				dispatch(fetchModes(data));
				FetchLiveModes(token, instLinker, online, dispatch, data);
			} else {
				saveToIDB("modes", payload.linker, payload);
				if (parseInt(instLinker) === parseInt(payload.instLinker)) {
					dispatch(updateMode(payload));
				}
			}
		})
		.catch((err) => console.log(err));
};

export const FetchLiveModes = (token, instLinker, online, dispatch, modes) => {
	fetch(`${ApiUrl}/mode/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				res.modes.map((onlineMode) => {
					let localMode = modes.find(
						(item) => parseInt(item.linker) === parseInt(onlineMode.linker)
					);
					if (!localMode) {
						saveToIDB("modes", onlineMode.linker, onlineMode);
						dispatch(updateMode(onlineMode));
					} else if (parseInt(localMode.trace) > parseInt(onlineMode.trace)) {
						updateLiveMode(localMode, token, dispatch);
					} else if (parseInt(localMode.trace) < parseInt(onlineMode.trace)) {
						saveToIDB("modes", onlineMode.linker, onlineMode);
						dispatch(updateMode(onlineMode));
					}
				});

				modes.map((mode) => {
					if (!mode.id) {
						addLiveMode(mode, instLinker, token, dispatch);
					} else if (parseInt(mode.live) === 0) {
						updateLiveMode(mode, token, dispatch);
					}
				});
			}
		})
		.catch(() => {});
};

export const addLiveMode = (mode, instLinker, token, dispatch) => {
	dispatch(updateMode({ ...mode, live: 0 }));
	saveToIDB("modes", mode.linker, { ...mode, live: 0 });
	fetch(`${ApiUrl}/mode/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...mode,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateMode(res.mode));
				saveToIDB("modes", res.mode.linker, res.mode);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {});
};

export const updateLiveMode = (mode, token, dispatch) => {
	dispatch(updateMode({ ...mode, live: 0 }));
	saveToIDB("modes", mode.linker, { ...mode, live: 0 });
	fetch(`${ApiUrl}/mode/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...mode,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateMode(res.mode));
				saveToIDB("modes", res.mode.linker, res.mode);
			} else {
				swal({
					title: "Failed to connect",
					text: "check your internet connection & try again",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {});
};

/*export const FetchModes = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveModes(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateMode(payload));
	}
};

export const addLiveMode = (mode, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/mode/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...mode,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateMode(res.mode));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveMode = (mode, token, dispatch) => {
	return fetch(`${ApiUrl}/mode/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...mode,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateMode(res.mode));
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				text: "check your internet connection & try again",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveModes = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/mode/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchModes(res.modes));
			}
		})
		.catch(() => {});
};
*/
